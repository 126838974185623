<template>
  <AuthWrapper class="notranslate">
    <div class="form_wrapper _____form__reset__password">
      <div class="___form_reset__inner">
        <div class="form__header">
          <h2 class="">
            {{ $t("auth.forgot_password.header") }}
          </h2>
          <hr class="" />
          <p style="width: 40ch; margin: 1rem auto">
            {{ $t("auth.forgot_password.sub_header") }}
          </p>
        </div>
        <div class="form">
          <div class="form_inner">
            <el-form
              ref="ruleForm"
              :model="formData"
              :rules="rules"
              @submit.prevent.native="checkEnter"
            >
              <div class="country__number__input">
                <div class="form_input_container number mb-3">
                  <label>{{ $t("auth.login.form.phone.label") }}</label>
                  <vue-phone-number-input
                    v-model="formattedPhone"
                    required
                    :color="'#f79b31'"
                    clearable
                    size="lg"
                    class="input-model"
                    @update="handleCountryChange"
                    default-country-code="GH"
                    :preferred-countries="[
                      'GH',
                      'CI',
                      'SN',
                      'BJ',
                      'GN',
                      'TG',
                      'NE',
                    ]"
                    :translations="{
                      countrySelectorLabel: $t(
                        'misc.phone_input.countrySelectorLabel'
                      ),
                      countrySelectorError: $t(
                        'misc.phone_input.countrySelectorError'
                      ),
                      phoneNumberLabel: $t('misc.phone_input.phoneNumberLabel'),
                      example: $t('misc.phone_input.example'),
                    }"
                  ></vue-phone-number-input>
                </div>
              </div>
              <div class="alert alert-success" v-if="enterAuthCode">
                <p>
                  {{ $t("auth.forgot_password.verification_successful") }}
                </p>
              </div>
              <div class="country__number__input" v-if="enterAuthCode">
                <div class="form_input_container number mb-3">
                  <el-form-item
                    :label="
                      $t('auth.forgot_password.form.verification_code.label')
                    "
                    prop="code"
                  >
                    <el-input
                      type="text"
                      :placeholder="
                        $t(
                          'auth.forgot_password.form.verification_code.placeholder'
                        )
                      "
                      v-model="formData.code"
                      autocomplete="false"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div>
                <Alert v-if="errors" :message="errors" :type="type" />
                <Alert
                  v-if="success"
                  :message="success"
                  :type="'alert-success'"
                />
                <el-form-item>
                  <el-button
                    id="mNotify_form_submit_btn"
                    @click="submitForm('ruleForm')"
                    :loading="loading"
                    type="primary"
                    >{{
                      enterAuthCode
                        ? $t(
                            "auth.forgot_password.form.reset_password.option_1"
                          )
                        : $t(
                            "auth.forgot_password.form.reset_password.option_2"
                          )
                    }}</el-button
                  >
                </el-form-item>
              </div>
              <div class="text-center return_to_login mt-2">
                <span
                  @click="changeRouter"
                  class="account_link is-a-link"
                  role="link"
                >
                  {{ $t("auth.footer.return_to_login") }}
                </span>
              </div>
            </el-form>
          </div>
        </div>
        <div class="mnotify__form_upper_link text-center">
          <p class="text_right_corner text-center">
            {{ $t("auth.footer.no_account") }}
            <router-link
              style="cursor: pointer"
              to="/signup"
              class="account_link"
            >
              {{ $t("auth.footer.sign_up") }}</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
  import store from "@/state/store.js";
  import AuthWrapper from "./AuthWrapper.vue";
  export default {
    data() {
      var validatePass = (rule, value, callback) => {
        if (!this.formData.phone) {
          callback(new Error("Please phone number is required."));
        } else {
          callback();
        }
      };
      return {
        phoneData: Object,
        formattedPhone: "",
        enterAuthCode: false,
        isEditing: false,
        formData: {
          phone: "",
          code: "",
        },
        rules: {
          phone: [
            { validator: validatePass, trigger: "change" },
            {
              pattern: /\+?\d+/g,
              message: "(+) sign is not required.",
            },
          ],
          code: [
            {
              required: true,
              message: "Enter password verification code.",
            },
            {
              max: 4,
              message:
                "Password verification code shouldn't be more than 4 digits.",
            },
          ],
        },
        loading: false,
        success: "",
        errors: "",
        type: "alert-danger",
      };
    },
    beforeMount() {
      window.addEventListener("beforeunload", (event) => {
        if (!this.isEditing) return;
        event.preventDefault();
        event.returnValue = "";
      });
    },
    computed: {
      countryCode() {
        return store.getters["auth/countryCode"];
      },
    },
    components: {
      AuthWrapper: AuthWrapper,
    },
    methods: {
      handleCountryChange(payload) {
        this.phoneData = payload;
        return this.phoneData;
      },

      resetPassword() {
        store
          .dispatch("auth/getResetPasswordVerificationCode", this.formData)
          .then((response) => {
            if (response) {
              this.loading = false;
              this.$router.push("/signin").catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
              // Set true to show reset password window
              store.commit("auth/SET_RESET_WINDOW_VISIBLE", true);
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 404) {
              this.errors =
                this.$t("auth.forgot_password.form.otp.invalid") ||
                err.response.data.message;
            }
            if (err.response?.data == undefined) {
              this.errors = "Network Error.";
            }
          });
      },
      getPasswordVerification() {
        store
          .dispatch("auth/resetPassword", this.formData)
          .then((response) => {
            if (response) {
              store.dispatch("uistate/setResetPasswordSuccessMessage", {
                phone: this.formData.phone,
              });
              this.loading = false;
              this.enterAuthCode = true;
              this.isEditing = true;
            }
            // Set needs to reset password to 1 to trigger
            localStorage.setItem("needsToResetPassword", 1);
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.status === 404) {
              this.errors =
                this.$t("auth.forgot_password.form.phone.does_not_exist") ||
                err.response.data.message;
            }
            if (err.response?.data == undefined) {
              this.errors = "Network Error.";
            }
          });
      },
      submitForm(formName) {
        this.errors = "";
        this.success = "";
        // Set Phone Number
        this.formData.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true;
            return this.enterAuthCode
              ? this.resetPassword()
              : this.getPasswordVerification();
          } else {
            return false;
          }
        });
      },
      changeRouter() {
        store.dispatch("uistate/setResetPasswordSuccessMessage", {
          phone: "",
        });
        this.$router.push("/signin").catch((err) => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      },
    },
  };
</script>
<style scoped>
  #logo {
    width: 140px;
    height: 50px;
  }

  .country__number__input {
    display: flex;
    justify-content: space-between;
  }

  .country {
    width: 30%;
  }

  .number {
    width: 100%;
  }

  .__sm {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .__margin_top {
      margin-top: 1em !important;
    }

    .__sm {
      display: block;
      text-align: center;
      padding-top: 1em;
    }
  }

  .is-a-link {
    cursor: pointer;
  }

  .is-a-link:hover {
    text-decoration: underline;
  }
</style>
