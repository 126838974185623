<template>
  <AuthWrapper
    v-loading.fullscreen.lock="fullscreenLoading"
    class="notranslate"
  >
    <div class="form_wrapper _____form__signin">
      <div class="inner____form__sign-in">
        <div class="form__header">
          <h2 class="">
            {{ $t("auth.verification.header") }}
          </h2>
          <hr />
        </div>
        <div class="form">
          <div class="form_inner">
            <p class="reset_password_text">
              {{ $t("auth.verification.sub_header") }}

              <strong>{{ currentUser ? currentUser.phone : "" }}</strong>
            </p>
            <p class="wrong_number">
              <button @click="returnBack">
                {{ $t("auth.verification.wrong_number") }}
              </button>
            </p>
            <el-form action="" @submit.prevent.native="checkEnter">
              <div class="pin__input__container">
                <div
                  class="form_input_container veriy_account_input_text_padding_right verify_pin"
                >
                  <el-form-item prop="number1">
                    <el-input
                      type="text"
                      autocomplete="false"
                      @input="handleOneChange"
                      autofocus
                      ref="number1"
                      maxlength="1"
                      v-model="formData.number1"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="form_input_container veriy_account_input_text_padding_right verify_pin"
                >
                  <el-form-item prop="number1">
                    <el-input
                      type="text"
                      autocomplete="false"
                      @input="handleTwoChange"
                      ref="number2"
                      maxlength="1"
                      v-model="formData.number2"
                    ></el-input>
                  </el-form-item>
                </div>
                <div
                  class="form_input_container veriy_account_input_text_padding_right verify_pin"
                >
                  <el-form-item prop="number1">
                    <el-input
                      type="text"
                      autocomplete="false"
                      @input="handleThreeChange"
                      ref="number3"
                      maxlength="1"
                      v-model="formData.number3"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form_input_container verify_pin">
                  <el-form-item prop="number1">
                    <el-input
                      type="text"
                      autocomplete="false"
                      @input="handleChangeFourch"
                      ref="number4"
                      maxlength="1"
                      v-model="formData.number4"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <p class="enter_authentication mb-4">
                {{ $t("auth.verification.enter_authentication_code") }}
              </p>
              <div v-if="errors">
                <Alert :message="errors" :type="type" />
              </div>
              <div class="pt-2">
                <el-form-item>
                  <el-button
                    id="mNotify_form_submit_btn"
                    ref="submit"
                    :loading="loading"
                    type="primary"
                    @click="submitForm"
                  >
                    {{ $t("auth.verification.submit") }}
                  </el-button>
                </el-form-item>
              </div>

              <div class="remaining___text" v-if="timerCount > 0">
                <strong
                  >{{ $t("auth.verification.remaining_time") }}
                  {{ timerCountInMinuteSecond }}</strong
                >
              </div>
              <div v-else class="bottom_texts text-center">
                <p class="did_not_receive_code">
                  {{ $t("auth.verification.did_not_receive_code") }}
                </p>
                <p class="resend_code">
                  <button
                    class="mr-3 resend-code"
                    :loading="resendLoading"
                    type="button"
                    @click.prevent="resendVerificationCode"
                  >
                    {{ $t("auth.verification.resend_code") }}
                  </button>
                </p>
                <small class="text-danger" v-if="resendError">{{
                  resendError
                }}</small>
                <button @click.prevent="voiceActivation" class="call_instead">
                  <img
                    height="20px"
                    width="20px"
                    src="../../assets/Calling.png"
                    alt="calling"
                    style="margin-right: 10px"
                  />
                  {{ $t("auth.verification.call_instead") }}
                </button>
              </div>
            </el-form>
          </div>
        </div>
        <div class="mnotify__form_upper_link">
          <p class="text_right_corner text-center">
            {{ $t("auth.verification.no_account") }}
            <button
              style="cursor: pointer"
              @click="signup"
              class="account_link"
            >
              {{ $t("auth.verification.sign_up") }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>

<script>
  import store from "@/state/store";
  import $ from "jquery";
  import AuthWrapper from "./AuthWrapper.vue";
  import Storage from "../../services/storage";
  import { francoCountries, setCountry } from "@/state/modules/auth";
  export default {
    name: "verify-account",
    components: {
      AuthWrapper,
    },
    data() {
      return {
        formData: {
          number1: "",
          number2: "",
          number3: "",
          number4: "",
        },
        showModal: false,
        resendCode: "",
        loading: false,
        errors: "",
        type: "alert-danger",
        timerCount: 45,
        resendError: "",
        resendLoading: false,
        callLoading: false,
        callError: "",
        callSuccess: "",
        fullscreenLoading: false,
        currentUserMounted: {},
      };
    },
    computed: {
      currentUser() {
        return this.currentUserMounted;
      },
      timerCountInMinuteSecond() {
        let minutes = Math.floor(this.timerCount / 60);
        let seconds = Math.floor(this.timerCount % 60);
        return `${minutes} : ${seconds}`;
      },
      tempActivateNumber: {
        get() {
          return store.getters["auth/getNumberToVerify"];
        },
        set(value) {
          store.dispatch("auth/setNumberToVerify", value);
        },
      },
    },
    watch: {
      timerCount: {
        handler(value) {
          if (value > 0) {
            setTimeout(() => {
              this.timerCount--;
            }, 1000);
          }
        },
        immediate: true,
      },
    },
    methods: {
      signup() {
        let storage = new Storage();
        storage.clearSavedData("token");
        storage.clearSavedData("currentUser");
        store.dispatch("auth/setNumberToVerify", "");
        this.$router.replace("/signup").catch(() => {});
      },
      handleOneChange() {
        this.$refs.number2.focus();
      },
      handleTwoChange() {
        this.$refs.number3.focus();
      },
      handleThreeChange() {
        this.$refs.number4.focus();
      },
      handleChangeFourch() {
        this.submitForm();
      },
      showResendAccountModal() {
        $("#deletecontact").modal("show");
      },
      voiceActivation() {
        this.callLoading = true;
        this.fullscreenLoading = true;
        this.callError = "";
        let data = {
          phone: this.currentUser.phone,
          otp_method: "voice",
        };
        store
          .dispatch("auth/voiceActivation", data)
          .then((response) => {
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success",
            });
            this.$mixpanel.track("Used Voice Activation", {
              Phone: this.currentUser.phone,
            });
            if (this.currentUser.country === "CI") {
              this.$mixpanelFrance.track("Used Voice Activation", {
                Phone: this.currentUser.phone,
              });
            }

            this.timerCount = 80;
          })
          .catch((err) => {
            if (err.response.data === undefined) {
              this.resendError = this.$t("misc.general_error");
            }
            if (err.response.status === 404) {
              this.resendError = err.response.data.data;
            }
            this.$notify({
              title: "Error",
              message: err.response.data.message,
              type: "error",
            });
          })
          .finally(() => {
            this.resendLoading = false;
            this.fullscreenLoading = false;
          });
      },
      resendVerificationCode() {
        this.resendLoading = true;
        this.fullscreenLoading = true;
        this.resendError = "";
        let data = {
          phone: this.currentUser.phone,
          otp_method: "sms",
        };
        store
          .dispatch("auth/resendActivationCode", data)
          .then((response) => {
            this.$notify({
              title: "Success",
              message: response.data.message,
              type: "success",
            });
            this.formData = {};
            this.timerCount = 80;
          })
          .catch((err) => {
            if (err.response.data === undefined) {
              this.resendError = this.$t("misc.general_error");
            }
            if (err.response.status === 404) {
              this.resendError = err.response.data.data;
            }
          })
          .finally(() => {
            this.resendLoading = false;
            this.fullscreenLoading = false;
          });
      },
      resetModal() {
        this.resendCode = null;
        this.resendError = null;
        this.loading = false;
      },
      submitForm() {
        this.loading = true;
        this.errors = "";
        if (
          !this.formData.number1 ||
          !this.formData.number2 ||
          !this.formData.number3 ||
          !this.formData.number4
        ) {
          this.errors = this.$t("auth.verification.enter_authentication_code");
          this.loading = false;
          return;
        }
        let authCode =
          this.formData.number1 +
          this.formData.number2 +
          this.formData.number3 +
          this.formData.number4;
        let data = { code: authCode, phone: this.currentUser.phone };
        store
          .dispatch("auth/activateAccount", data)
          .then((response) => {
            this.loading = false;
            let ssmall = new Storage();
            ssmall.saveState("token", response.data.data.token);
            ssmall.saveState("currentUser", response.data.data);
            ssmall.saveState("isAuthenticated", true);
            let payload = response.data.data;
            // if (
            //   payload.country === `Cote d'Ivoire` ||
            //   payload.country === "CI" ||
            //   payload.country_code === "CI"
            // ) {
            //   payload.country = "CI";
            // } else {
            //   payload.country = "GH";
            // }
            payload.country = setCountry(payload.country_code);
            ssmall.saveAndDelete("currentUser", payload);
            this.$router.push("/login_type").catch((err) => {});
            this.$notify({
              title: this.$t("misc.toast.success"),
              message: this.$t("auth.verification.activated_successful"),
              type: "success",
            });
            localStorage.setItem("TempActivateNumber", "");
          })
          .catch((err) => {
            this.loading = false;
            if (err.response.data === undefined) {
              this.errors = this.$t("misc.general_error");
            }
            if (err.response.status === 404) {
              this.errors = err.response.data.message;
            }
            if (err.response.data) {
              this.errors = err.response.data.message;
            }
          });
      },
      returnToLogin() {
        this.$router.push("/signin").catch((err) => {});
      },
      returnBack() {
        this.$router.push("/signup").catch((err) => {});
      },
    },
    mounted() {
      // Push user to signup page if current user is null
      this.currentUserMounted = store.getters["auth/currentUser"];
      if (this.currentUserMounted === null) {
        if (
          this.tempActivateNumber === null ||
          this.tempActivateNumber === ""
        ) {
          this.$router.push("/signup").catch((err) => {});
          this.$notify({
            title: this.$t("misc.toast.error"),
            message: this.$t("auth.verification.sign_to_activate"),
            type: "error",
          });
        } else {
          this.currentUserMounted = {
            phone:
              this.tempActivateNumber == ""
                ? localStorage.getItem("TempActivateNumber")
                : this.tempActivateNumber,
          };
        }
      }
    },
  };
</script>
<style scoped>
  #logo {
    width: 140px;
    height: 50px;
  }

  .account_link {
    outline: none;
    border: none;
    background: transparent;
  }

  .call_instead {
    outline: none;
    border: none;
    background: transparent;
  }

  .enter_authentication {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #484a4f;
  }

  .continue {
    background-color: #f7921c;
  }

  .resendto {
    color: #484a4f;
  }

  @media screen and (max-width: 768px) {
    #logo {
      width: 100px;
    }

    #mNotify__signin__form_fields_container {
      background-image: url("../../assets/tech.jpg");
      width: 100%;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .mnotify_form__inner__wrapper {
      background-color: #fff;
      width: 60%;
      margin: auto auto;
      height: 570px !important;
    }
  }

  @media screen and (max-width: 500px) {
    .mnotify_form__inner__wrapper {
      width: 90%;
    }
  }
</style>
