var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AuthWrapper',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.pageLoading),expression:"pageLoading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"notranslate"},[(_vm.showWindowState)?_c('ChangeTemporalPassword',{attrs:{"ResetPhoneNumber":this.formData.phone},on:{"needsRefresh":_vm.needsRefresh}}):_c('div',{staticClass:"form_wrapper _____form__signin"},[_c('div',{staticClass:"inner____form__sign-in"},[_c('div',{staticClass:"form__header"},[_c('h2',{},[_vm._v(" "+_vm._s(_vm.$t("auth.login.header"))+" ")]),_c('hr'),_c('p',{staticClass:"notranslate"},[_vm._v(_vm._s(_vm.$t("auth.login.login_sub_text")))])]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form_inner"},[(_vm.showResetMessage)?_c('div',{staticClass:"alert alert-success"},[_vm._v(" "+_vm._s(_vm.$t("auth.forgot_password.reset_successful"))+" ")]):_vm._e(),_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEnter.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitForm('ruleForm')}}},[_c('div',{staticStyle:{"margin":"0px !important","padding":"0px !important"}},[_c('el-form-item',{staticStyle:{"margin":"0px !important","padding":"0px !important"},attrs:{"label":_vm.$t('auth.login.form.phone.label'),"required":""}})],1),_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"prop":"phone"}},[_c('vue-phone-number-input',{staticClass:"input-model",attrs:{"color":'#f79b31',"clearable":"","size":"lg","fetch-country":"","preferred-countries":[
                    'GH',
                    'CI',
                    'SN',
                    'BJ',
                    'GN',
                    'TG',
                    'NE',
                  ],"required":true,"error":_vm.inputError,"translations":{
                    countrySelectorLabel: _vm.$t(
                      'misc.phone_input.countrySelectorLabel'
                    ),
                    countrySelectorError: _vm.$t(
                      'misc.phone_input.countrySelectorError'
                    ),
                    phoneNumberLabel: _vm.$t('misc.phone_input.phoneNumberLabel'),
                    example: _vm.$t('misc.phone_input.example'),
                  }},on:{"update":_vm.handleCountryChange},model:{value:(_vm.formattedPhone),callback:function ($$v) {_vm.formattedPhone=$$v},expression:"formattedPhone"}})],1)],1),_c('div',{staticClass:"form_input_container"},[_c('el-form-item',{attrs:{"label":_vm.$t('auth.login.form.password.label'),"prop":"password"}},[_c('el-input',{attrs:{"show-password":"","type":"password","autocomplete":"off","placeholder":_vm.$t('auth.login.form.password.placeholder')},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}})],1)],1),(_vm.errors)?_c('div',{staticStyle:{"margin-top":"3em"}},[_c('Alert',{attrs:{"message":_vm.errors,"type":_vm.type}})],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('el-form-item',[_c('router-link',{staticClass:"account_link float-right",attrs:{"to":"/forget-password"}},[_vm._v(" "+_vm._s(_vm.$t("auth.login.forgot_password"))+" ")])],1)],1),_c('div',{staticClass:"terms_and_conditons_container"},[_c('el-form-item',[_c('el-button',{attrs:{"id":"mNotify_form_submit_btn","loading":_vm.loading,"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(" "+_vm._s(_vm.$t("auth.login.header"))+" ")])],1)],1)])],1)]),_c('div',{staticClass:"mnotify__form_upper_link"},[_c('p',{staticClass:"text_right_corner text-center"},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.no_account"))+" "),_c('router-link',{staticClass:"account_link",staticStyle:{"cursor":"pointer"},attrs:{"to":"/signup"}},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.sign_up"))+" ")])],1),(!_vm.isFrenchUser)?_c('p',{staticClass:"text_right_corner"},[_c('a',{staticClass:"support-link",attrs:{"href":"tel:+233541509394"}},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.support"))+" ")])]):_vm._e(),(!_vm.isFrenchUser)?_c('a',{staticClass:"text_right_corner support-link",attrs:{"href":"https://mnotify.tawk.help/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.view_guide"))+" ")]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }