var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AuthWrapper',{staticClass:"notranslate"},[_c('div',{staticClass:"form_wrapper _____form__reset__password"},[_c('div',{staticClass:"___form_reset__inner"},[_c('div',{staticClass:"form__header"},[_c('h2',{},[_vm._v(" "+_vm._s(_vm.$t("auth.forgot_password.header"))+" ")]),_c('hr',{}),_c('p',{staticStyle:{"width":"40ch","margin":"1rem auto"}},[_vm._v(" "+_vm._s(_vm.$t("auth.forgot_password.sub_header"))+" ")])]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"form_inner"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkEnter.apply(null, arguments)}}},[_c('div',{staticClass:"country__number__input"},[_c('div',{staticClass:"form_input_container number mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("auth.login.form.phone.label")))]),_c('vue-phone-number-input',{staticClass:"input-model",attrs:{"required":"","color":'#f79b31',"clearable":"","size":"lg","default-country-code":"GH","preferred-countries":[
                    'GH',
                    'CI',
                    'SN',
                    'BJ',
                    'GN',
                    'TG',
                    'NE',
                  ],"translations":{
                    countrySelectorLabel: _vm.$t(
                      'misc.phone_input.countrySelectorLabel'
                    ),
                    countrySelectorError: _vm.$t(
                      'misc.phone_input.countrySelectorError'
                    ),
                    phoneNumberLabel: _vm.$t('misc.phone_input.phoneNumberLabel'),
                    example: _vm.$t('misc.phone_input.example'),
                  }},on:{"update":_vm.handleCountryChange},model:{value:(_vm.formattedPhone),callback:function ($$v) {_vm.formattedPhone=$$v},expression:"formattedPhone"}})],1)]),(_vm.enterAuthCode)?_c('div',{staticClass:"alert alert-success"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("auth.forgot_password.verification_successful"))+" ")])]):_vm._e(),(_vm.enterAuthCode)?_c('div',{staticClass:"country__number__input"},[_c('div',{staticClass:"form_input_container number mb-3"},[_c('el-form-item',{attrs:{"label":_vm.$t('auth.forgot_password.form.verification_code.label'),"prop":"code"}},[_c('el-input',{attrs:{"type":"text","placeholder":_vm.$t(
                        'auth.forgot_password.form.verification_code.placeholder'
                      ),"autocomplete":"false"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}})],1)],1)]):_vm._e(),_c('div',[(_vm.errors)?_c('Alert',{attrs:{"message":_vm.errors,"type":_vm.type}}):_vm._e(),(_vm.success)?_c('Alert',{attrs:{"message":_vm.success,"type":'alert-success'}}):_vm._e(),_c('el-form-item',[_c('el-button',{attrs:{"id":"mNotify_form_submit_btn","loading":_vm.loading,"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(_vm._s(_vm.enterAuthCode ? _vm.$t( "auth.forgot_password.form.reset_password.option_1" ) : _vm.$t( "auth.forgot_password.form.reset_password.option_2" )))])],1)],1),_c('div',{staticClass:"text-center return_to_login mt-2"},[_c('span',{staticClass:"account_link is-a-link",attrs:{"role":"link"},on:{"click":_vm.changeRouter}},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.return_to_login"))+" ")])])])],1)]),_c('div',{staticClass:"mnotify__form_upper_link text-center"},[_c('p',{staticClass:"text_right_corner text-center"},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.no_account"))+" "),_c('router-link',{staticClass:"account_link",staticStyle:{"cursor":"pointer"},attrs:{"to":"/signup"}},[_vm._v(" "+_vm._s(_vm.$t("auth.footer.sign_up")))])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }