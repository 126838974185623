<script>
  /* eslint-disable */
  import store from "@/state/store.js";
  import AuthWrapper from "./AuthWrapper.vue";
  export default {
    components: {
      AuthWrapper,
    },
    data() {
      var validatePass2 = (rule, value, callback) => {
        if (this.formData.password_confirmation !== this.formData.password) {
          callback(
            new Error(this.$t("auth.sign_up.form.confirm_password.not_match"))
          );
        } else {
          callback();
        }
      };
      return {
        phoneData: {
          isValid: false,
        },
        inputError: false,
        formattedPhone: "",
        formData: {
          phone: "",
          country: "",
          password: "",
          password_confirmation: "",
          email: "",
          otp_method: "sms",
          referral_code: "",
          have_referal_code: "",
          terms: "",
          country_code: "",
        },
        placeholder: "eg:054XXXXXXX or +233 54XXXXXXX",
        checkBox: "",
        type: "alert-danger",
        loading: false,
        referral_code: false,

        sideImageStyle: {},
        errors: [],
        rules: {
          phone: [
            {
              required: true,
              message: "Phone number is required.",
              trigger: "change",
            },
            {
              min: 4,
              max: 13,
              message: `Enter a valid phone number`,
              trigger: "blur",
            },
          ],
          email: [
            {
              type: "email",
              message: this.$t("auth.sign_up.form.email.invalid"),
              trigger: "change",
            },
            {
              required: true,
              message: this.$t("auth.sign_up.form.email.required"),
              trigger: "change",
            },
          ],
          password: [
            {
              required: true,
              message: this.$t("auth.sign_up.form.password.required"),
              trigger: ["blur", "change"],
            },
            {
              min: 6,
              message: this.$t("auth.sign_up.form.password.length"),
              trigger: "blur",
            },
          ],
          country: [
            { required: true, message: "Select country", trigger: "change" },
          ],
          password_confirmation: [
            { validator: validatePass2, trigger: "blur" },
            {
              required: true,
              message: this.$t("auth.sign_up.form.confirm_password.invalid"),
              trigger: "change",
            },
            {
              min: 6,
              message: this.$t("auth.sign_up.form.confirm_password.length"),
              trigger: "blur",
            },
          ],
          referral_code: [
            { required: true, message: "Enter referal code.", trigger: "blur" },
          ],
          terms: [
            {
              required: true,
              message: this.$t(
                "auth.sign_up.form.terms_and_conditions.invalid"
              ),
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {
      countryCode() {
        return store.getters["auth/countryCode"];
      },
    },
    methods: {
      logFormData() {},
      handleCountryChange(payload) {
        this.phoneData = payload;
        return this.phoneData;
      },

      submitForm(formName) {
        this.errors = [];
        this.loading = true;

        if (this.phoneData.isValid == false) {
          this.loading = false;
          this.errors.push(new Array(this.$t("misc.phone.invalid")));
          this.inputError = true;
          setTimeout(() => {
            this.inputError = false;
            this.errors = [];
          }, 5000);
          return;
        } else {
          // Set Country Code
          this.formData.country_code = this.phoneData.countryCode;
          // Set Country Name
          this.formData.country = this.countryCode.find(
            (country) => country.code == this.phoneData.countryCode
          ).name;
          // Set Phone Number
          this.formData.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`;

          this.$refs[formName].validate((valid) => {
            if (valid) {
              if (!this.formData.terms) {
                this.loading = false;
                return;
              }
              store
                .dispatch("auth/register", this.formData)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    this.$mixpanel.track("Sign Up", {
                      Page: this.$router.path,
                      Email: this.formData.email,
                      Phone: this.formData.phone,
                      Country: this.formData.country,
                      "Country Code": this.formData.country_code,
                      "Referral Code": this.formData.referral_code,
                      "Have Referral Code": this.formData.have_referal_code,
                    });
                    this.$mixpanel.identify(this.formData.phone);
                  }
                  // check if cote d'ivoire
                  if (this.formData.country_code == "CI") {
                    this.$mixpanelFranco.track("Sign Up", {
                      Page: this.$router.path,
                      Email: this.formData.email,
                      Phone: this.formData.phone,
                      Country: this.formData.country,
                      "Country Code": this.formData.country_code,
                      "Referral Code": this.formData.referral_code,
                      "Have Referral Code": this.formData.have_referal_code,
                    });
                    this.$mixpanelFranco.identify(this.formData.phone);
                  }
                  // Clean profile data in local storage
                  localStorage.removeItem("profileSetup");
                  localStorage.removeItem("emailVerified");
                  localStorage.removeItem("TempActivateNumber");
                  // Push to verify account page
                  this.$router.push("/verify-account").catch((err) => {
                    if (err.name !== "NavigationDuplicated") throw err;
                  });
                })
                .catch((err) => {
                  this.loading = false;

                  if (err.response?.data == undefined) {
                    this.errors.push(new Array(this.$t("misc.general_error")));
                    setTimeout(() => {
                      this.errors = [];
                    }, 7000);
                  }
                  if (err.response && err.response.status === 404) {
                    this.errors = Object.values(err.response.data.data);
                    setTimeout(() => {
                      this.errors = [];
                    }, 7000);
                  }
                  if (err.response && err.response.status === 480) {
                    // this.formData.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`
                    store
                      .dispatch(
                        "auth/setNumberNeedToVerify",
                        this.formData.phone
                      )
                      .then(() => {
                        this.$router.push("/verify-account").catch((err) => {
                          if (err.name !== "NavigationDuplicated") throw err;
                        });
                      });
                  }
                  if (err.response && err.response.status === 481) {
                    this.errors = err.response.data.data.error;
                  }
                });
            } else {
              this.loading = false;
              return false;
            }
          });
        }
      },
    },
    mounted() {
      this.$mixpanel.track_links(
        "#mNotifyTerms_link",
        "Opened terms and conditions",
        {
          referrer: document.referrer,
        }
      );
    },
  };
</script>
<style scoped>
  .text_description {
    color: #ff5656;
    font-size: 12px;
    margin-bottom: 0px;
  }

  .__description__ {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0px !important;
  }

  .country_number_container {
    width: 100%;
    display: flex;
  }

  .country {
    width: 30%;
  }

  @media screen and (max-width: 768px) {
    .mnotify__form_upper_link {
      display: none;
    }

    .mnotify_form__inner__wrapper {
      background-color: #fff;
      width: 100%;
      margin: 5em auto;
    }

    .form_title {
      margin-top: 1.5em;
    }

    .__lowesm {
      text-align: center;
      margin-bottom: 3em;
    }
  }

  .referal-container {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: -4rem;
    margin-top: -2rem;
  }

  .referal-check-container * {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-referal-container {
    flex: 1;
    width: 100%;
  }

  .form {
    /* background-color: springgreen; */
    /* height: 100%; */
    height: 80vh;
    overflow-y: scroll;
  }

  .form::-webkit-scrollbar {
    display: none;
  }

  .alerts-container {
    margin-bottom: -1rem;
  }

  @media screen and (max-width: 500px) {
    .__description__ {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .referal-check-container * {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .referal-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0;
      margin-bottom: 0;
    }

    .form {
      height: 100%;
      overflow: auto;
    }
  }

  .autoPhoneNumber {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .autoPhoneNumber * {
    width: 100%;
    /* gap: 1rem;
  border-radius: 20px; */
  }

  .input-model {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .terms {
    display: flex;
  }

  .i-have-read {
    font-size: 12px;
    margin-bottom: 0px;
    display: inline;
  }
</style>
<template>
  <AuthWrapper class="notranslate">
    <div class="form_wrapper __signUp">
      <div class="___signup___inner">
        <div class="form__header">
          <h2 class="">
            {{ $t("auth.sign_up.header") }}
          </h2>
          <hr class="" />
        </div>

        <div class="form">
          <div class="form_inner">
            <el-form
              ref="ruleForm"
              :model="formData"
              :rules="rules"
              @submit.prevent.native="checkEnter"
              @keyup.enter.native="submitForm('ruleForm')"
            >
              <div
                class="__description__"
                style="margin: 0px !important; padding: 0px !important"
              >
                <el-form-item
                  style="
                    float: left;
                    margin: 0px !important;
                    padding: 0px !important;
                  "
                  :label="$t('misc.phone.label')"
                  required=""
                >
                </el-form-item>
              </div>
              <div
                class="__c_code_phone d-flex justify-content-between autoPhoneNumber"
              >
                <div class="form_input_container">
                  <el-form-item prop="phone" required>
                    <vue-phone-number-input
                      v-model="formattedPhone"
                      :color="'#f79b31'"
                      clearable
                      size="lg"
                      class="input-model"
                      @update="handleCountryChange"
                      fetch-country
                      :preferred-countries="[
                        'GH',
                        'CI',
                        'SN',
                        'BJ',
                        'GN',
                        'TG',
                        'NE',
                      ]"
                      :required="true"
                      :error="inputError"
                      :translations="{
                        countrySelectorLabel: $t(
                          'misc.phone_input.countrySelectorLabel'
                        ),
                        countrySelectorError: $t(
                          'misc.phone_input.countrySelectorError'
                        ),
                        phoneNumberLabel: $t(
                          'misc.phone_input.phoneNumberLabel'
                        ),
                        example: $t('misc.phone_input.example'),
                      }"
                    ></vue-phone-number-input>
                  </el-form-item>
                </div>
              </div>

              <div class="form_input_container">
                <el-form-item
                  :label="$t('auth.sign_up.form.email.label')"
                  prop="email"
                >
                  <el-input
                    type="email"
                    placeholder="example@gmail.com"
                    v-model="formData.email"
                    readonly=""
                    onfocus="this.removeAttribute('readonly')"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="row">
                <div class="col-md-6 form_input_container">
                  <form action="#">
                    <el-form-item
                      :label="$t('auth.sign_up.form.password.label')"
                      prop="password"
                    >
                      <el-input
                        show-password
                        type="password"
                        :placeholder="
                          $t('auth.sign_up.form.password.placeholder')
                        "
                        v-model="formData.password"
                      ></el-input>
                    </el-form-item>
                  </form>
                </div>
                <div class="col-md-6 form_input_container">
                  <el-form-item
                    :label="$t('auth.sign_up.form.confirm_password.label')"
                    prop="password_confirmation"
                  >
                    <el-input
                      show-password
                      autocomplete="false"
                      type="password"
                      :placeholder="
                        $t('auth.sign_up.form.confirm_password.placeholder')
                      "
                      v-model="formData.password_confirmation"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <span v-if="errors.length" class="alerts-container">
                <Alert
                  v-for="(error, key) in errors"
                  :key="key"
                  :message="error[0]"
                  :type="type"
                  class="alerts"
                />
              </span>
              <div class="referal-container">
                <div
                  class="terms_and_conditons_container referal-check-container"
                >
                  <el-form-item
                    :label="$t('auth.sign_up.form.referal_code.label')"
                  >
                    <el-checkbox
                      style="margin-left: 0.5em; width: 15px; height: 15px"
                      id="mNotifyTerms"
                      v-model="formData.have_referal_code"
                      name="mNotifyTerms"
                    ></el-checkbox>
                  </el-form-item>
                </div>
                <div class="form_input_container input-referal-container">
                  <el-form-item
                    prop="referral_code"
                    v-if="formData.have_referal_code"
                    class="has-referal"
                  >
                    <el-input
                      type="text"
                      autocomplete="false"
                      :placeholder="
                        $t('auth.sign_up.form.referal_code.placeholder')
                      "
                      v-model="formData.referral_code"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="terms_and_conditons_container">
                <p class="terms">
                  <el-form-item prop="terms">
                    <el-checkbox
                      style="width: 15px; height: 15px; margin-right: 5px"
                      id="mNotifyTerms"
                      v-model="formData.terms"
                      name="mNotifyTerms"
                      required
                    ></el-checkbox>
                    <p class="i-have-read">
                      {{ $t("auth.sign_up.form.terms_and_conditions.label") }}
                    </p>
                    <a
                      target="_blank"
                      href="https://www.mnotify.com/terms-of-service/"
                      id="mNotifyTerms_link"
                    >
                      {{ $t("auth.sign_up.form.terms_and_conditions.link") }}
                    </a>
                  </el-form-item>
                  <!-- <br><small v-if="!formData.terms" class="text-danger"> You must agree to our terms and
                                conditions.</small> -->
                </p>
              </div>
              <div class="">
                <el-form-item>
                  <el-button
                    id="mNotify_form_submit_btn"
                    :loading="loading"
                    type="primary"
                    @click="submitForm('ruleForm')"
                  >
                    {{ $t("auth.sign_up.continue") }}
                  </el-button>
                </el-form-item>
              </div>
              <div class="__lowesm">
                <p class="text-center">
                  {{ $t("auth.sign_up.have_an_account") }}

                  <router-link to="/signin" class="account_link">
                    {{ $t("auth.sign_up.sign_in") }}
                  </router-link>
                </p>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>
